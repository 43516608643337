import React from 'react'
import PrivacyPolicy from '../components/PrivacyComponent'

export default function PrivacyPage() {
  return (
    <>
      <PrivacyPolicy />
    </>
  )
}
